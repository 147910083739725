.form-input {
  display: flex;
  min-width: 0;
  flex-flow: row wrap;
}
.form-input > label {
  height: fit-content;
  margin-bottom: 6px;
  white-space: nowrap;
  word-wrap: normal;
}
.form-input > label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.form-input > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.form-input > div {
  width: 100%;
}

.input-required label {
  margin-bottom: 8px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.input-required label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.input-required-msg {
  color: #f5222d;
  margin-top: 6px;
  font-size: 14px;
  line-height: 1.5715;
}

.input-label {
  padding-bottom: 8px;
}

.custom-upload .ant-tooltip {
  display: none !important;
}
.custom-upload .ant-upload-list-item-error {
  border: none !important;
}
.custom-upload .ant-upload-list-item {
  padding: 0 !important;
}
